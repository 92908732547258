/////////////////////////////////////////////////////////////
//! BREAKPOINTS /////////////////////////////////////////////
/////////////////////////////////////////////////////////////

export var Breakpoint = function(min, max) {
	this.min = min;
	this.max = max;
};

Breakpoint.prototype.getViewportWidth = function() {
	return document.documentElement.clientWidth;
};

Breakpoint.prototype.aboveMin = function() {
	return this.getViewportWidth() >= this.min;
};

Breakpoint.prototype.belowMax = function() {
	return this.getViewportWidth() <= this.max;
};

Breakpoint.prototype.inRange = function() {
	return this.aboveMin() && this.belowMax();
};

/**
 * Generate a set of breakpoints
 * @param array|boolean 	sizes	Array of objects
 */
export var Breakpoints = function(sizes) {
	var defaults = [
		{
			key: 'xxs',
			min: 320
		},
		{
			key: 'xs',
			min: 480
		},
		{
			key: 'sm',
			min: 768
		},
		{
			key: 'md',
			min: 1025
		},
		{
			key: 'lg',
			min: 1200
		}
	];

	this.sizes = sizes ? sizes : defaults;

	for(var i = 0; i < this.sizes.length; i++) {
		var key = this.sizes[i].key;
		var min = this.sizes[i].min;
		var max = this.sizes[i].max ? this.sizes[i].max : (this.sizes[i + 1] ? this.sizes[i + 1].min - 1 : Infinity);
		this[key] = new Breakpoint(min, max);
	}
};
