/**
 * Import dependencies.
 */
import * as helpers from './helpers';

/**
 * Placeholder for table elements.
 */
let tables;

/**
 * Wrap each table in necessary markup so that they can be scrolled if necessary.
 * @param  {Event} e
 */
function initResponsiveTables(e) {
	/**
	 * Get all tables.
	 * @type {Array}
	 */
	tables = document.querySelectorAll('.editor-styles table');

	/**
	 * Loop through all tables and wrap in necessary HTML.
	 */
	helpers.forEach(tables, (i, table) => {
		/**
		 * Table wrapper.
		 * @type {HTMLElement}
		 */
		const tableWrapper = document.createElement('div');
		tableWrapper.className = 'table--responsive';

		/**
		 * Table wrapper inner.
		 * @type {HTMLElement}
		 */
		const tableWrapperInner = document.createElement('div');
		tableWrapperInner.className = 'table--responsive__inner';

		/**
		 * Wrap the table.
		 */
		helpers.wrap(helpers.wrap(table, tableWrapper), tableWrapperInner);
	});
}

/**
 * Check if tables are wider than their container, and if so allow them to scroll.
 * @param  {Event} e
 */
function handleTableView(e) {
	if(tables.length > 0) {
		helpers.forEach(tables, (i, table) => {
			const container = table.parentNode.parentNode;
			if(table.offsetWidth > container.offsetWidth) {
				container.classList.add('overflow');
			} else {
				container.classList.remove('overflow');
			}
		});
	}
}

window.addEventListener('resize', helpers.debounce(handleTableView));
window.addEventListener('DOMContentLoaded', function(e) {
	initResponsiveTables(e);
	handleTableView(e);
});
