/**
 * Import dependencies.
 */
import * as helpers from './helpers';
import * as els from './elements';

/**
 * Tracks the current scroll position on the page.
 * @type {Number}
 */
let currentScrollPoint;

/**
 * The point (in pixels) at which the header class should be toggled.
 * @type {Number}
 */
const headerScrollPoint = 100;

/**
 * Add padding to <body> to accomodate for the header being fixed.
 * @param  {Event} e
 */
function bodyPadding(e) {
	els.body.style.paddingTop = els.header.offsetHeight + 'px';
}

/**
 * Toggle the header scrolled class.
 * @param  {Event} e
 */
function maybeToggleScrollStatus(e) {
	/**
	 * Update the current scroll position.
	 * @type {Number}
	 */
	currentScrollPoint = ( document.documentElement && document.documentElement.scrollTop ) || document.body.scrollTop;

	/**
	 * Check scroll status and toggle .scrolled accordingly.
	 */
	if ( currentScrollPoint >= headerScrollPoint ) {
		els.header.classList.add('scrolled');
	} else {
		els.header.classList.remove('scrolled');
	}
}

// window.addEventListener('resize', helpers.debounce(bodyPadding));
window.addEventListener('scroll', maybeToggleScrollStatus);
window.addEventListener('load', function(e) {
	// bodyPadding(e);
	maybeToggleScrollStatus(e);
});
