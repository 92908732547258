/**
 * Import dependencies.
 */
import * as helpers from './helpers';

function allowHtmlInGfieldLabels() {
	const labels = document.querySelectorAll('.gfield_label');
	helpers.forEach(labels, (i, label) => label.innerHTML = helpers.decodeHTML(label.innerHTML));
}

function handleSelectOther(e) {
	if(e.target.nodeName === 'SELECT') {
		const select = e.target;
		const otherInputSibling = select.parentElement.classList.contains('selector') ? select.parentElement : select;

		if(e.target.value.toUpperCase() === 'OTHER') {
			const otherInput = document.createElement('input');
			otherInput.type = 'text';
			otherInput.name = select.name;
			otherInput.placeholder = 'Other';
			otherInput.classList.add('other-input');
			helpers.insertAfter(otherInput, otherInputSibling);
			select.name = '';
		} else if(otherInputSibling.nextSibling) {
			select.name = otherInputSibling.nextSibling.name;
			otherInputSibling.parentElement.removeChild(otherInputSibling.nextSibling);
		}
	}
}

function initForms(e) {
	allowHtmlInGfieldLabels();
}

window.addEventListener('load', allowHtmlInGfieldLabels);
document.addEventListener('change', handleSelectOther);
