/**
 * Import dependencies.
 */
import * as settings from './settings';
import * as helpers from './helpers';

/**
 * Screensize that the hot spot view should toggle from accordions to the true hot spot view.
 * @type {Number}
 */
const hotSpotViewBreakpoint = settings.breakpoints.sm.min;

/**
 * The active class for pins and slides.
 * @type {String}
 */
const activeClass = 'active';

/**
 * Elements containing hot spot pins.
 * @type {Array}
 */
const pinContainers = document.querySelectorAll('.hot-spot-image__pins');

/**
 * All hot spot pins.
 * @type {Array}
 */
const pins = document.querySelectorAll('.hot-spot-image__pin');

/**
 * Elements containing hot spot slides.
 * @type {Array}
 */
const slideContainers = document.querySelectorAll('.hot-spot-image__slides');

/**
 * Set the height of each .hot-spot-image__slides since all its children are positioned absolute.
 * @param  {Event} e
 */
function matchSlideHeights(e) {
	console.log(e);
	/**
	 * Loop through each .hot-spot-image__slides in order to set their heights
	 * to the tallest chilend element they contain.
	 */
	helpers.forEach(slideContainers, (i, slideContainer) => {
		/**
		 * Only proceed if the viewport is the right size.
		 */
		if(window.innerWidth >= hotSpotViewBreakpoint) {
			const slides = slideContainer.querySelectorAll('.hot-spot-image__slide');
			const slideHeights = [];

			/**
			 * Loop through each content item to find the tallest.
			 */
			helpers.forEach(slides, (i, slide) => slideHeights.push(slide.offsetHeight));

			/**
			 * Set the height of contents to the tallest child element.
			 */
			slideContainer.style.height = Math.max(...slideHeights) + 'px';
		}
		/**
		 * Otherwise, set the height to auto.
		 */
		else {
			slideContainer.style.height = 'auto';
		}

		if(!slideContainer.classList.contains('initialized')) {
			slideContainer.classList.add('initialized');
		}
	});
}

/**
 * Initialize all instances of hot spot images.
 * @param  {Event} e
 */
function initHotSpotImages(e) {
	/**
	 * Make sure slide container is the proper height.
	 */
	matchSlideHeights(e);

	/**
	 * Setup active states for the first pin and slide of each hot spot image.
	 */
	helpers.forEach(pinContainers, (i, pinContainer) => {
		/**
		 * Add active class to first pin of each hot spot image.
		 */
		pinContainer.firstChild.classList.add(activeClass);

		/**
		 * Add active class to first content item of each hot spot image.
		 */
		document.querySelector(pinContainer.firstChild.dataset.target).classList.add(activeClass);
	});
}

/**
 * Click event for hot spot pins.
 * @param  {Event} e
 */
function handlePinClick(e) {
	/**
	 * Make sure slide container is the proper height.
	 */
	matchSlideHeights(e);

	/**
	 * Get the target hot spot and content.
	 */
	const targetPin = this;
	const targetSlide = document.querySelector(targetPin.dataset.target);

	/**
	 * Get the active hot spot and content.
	 */
	const activePin = targetPin.parentElement.querySelector('.' + activeClass);
	const activeSlide = targetSlide.parentElement.querySelector('.' + activeClass);

	/**
	 * Remove active class from active hot spot and container.
	 */
	activePin.classList.remove(activeClass);
	activeSlide.classList.remove(activeClass);

	/**
	 * Add active class to target hot spot and container.
	 */
	targetPin.classList.add(activeClass);
	targetSlide.classList.add(activeClass);
}

/**
 * Add event listeners.
 */
window.addEventListener('load', initHotSpotImages);
window.addEventListener('resize', helpers.debounce(matchSlideHeights));
window.addEventListener('orientationchange', helpers.debounce(matchSlideHeights));
helpers.forEach(pins, (i, pin) => pin.addEventListener('click', handlePinClick));
