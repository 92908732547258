/**
 * Import dependencies.
 */
import $ from 'jquery';

/**
 * Check if element is fully visible within the viewport.
 * @param  {Object}  el HTMLElement or jQuery Object.
 * @return {Boolean}
 */
export function isElementInViewport(el) {

    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
}

/**
 * Append a hash to the current URL.
 * @param {String} hash
 */
export function addHashToURL(hash) {
	if (history.pushState) {
		var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + hash;
		window.history.pushState({path:newurl},'',newurl);
	}
}

/**
 * Scroll the document to the provided element.
 * @param  {Object} 	el     	jQuery object
 * @param  {Integer} 	offset 	Offset from the top of the viewport
 */
export function scrollToEl(el, offset) {
	$('html, body').animate({
		scrollTop: el.offset().top - offset
	}, speed);
}

/**
 * Wrap element in another element.
 * @param  {HTMLElement} el      Element to be wrapped.
 * @param  {HTMLElement} wrapper Element to wrap around el.
 */
/**
 * Wrap element in another element.
 * @param  {HTMLElement} el      Element to be wrapped.
 * @param  {HTMLElement} wrapper Element to wrap around el.
 * @return {HTMLElement}         Wrapped element.
 */
export function wrap(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    return wrapper.appendChild(el);
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * @param  {Function} 	func      	Function to debounce
 * @param  {Number} 	wait      	Time to wait in milliseconds
 * @param  {Boolean} 	immediate 	If true is passed, trigger the function on the leading edge, instead of the trailing.
 * @return {Function}
 */
export function debounce(func, wait = 100, immediate = true) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

/**
 * Decode encoded HTML.
 *
 * @link https://stackoverflow.com/questions/7394748/whats-the-right-way-to-decode-a-string-that-has-special-html-entities-in-it
 *
 * @param  {String} html 	Encoded html to be decoded.
 * @return {String}       	Decoded html.
 */
export function decodeHTML(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

/**
 * Insert new node after reference node.
 *
 * @link https://stackoverflow.com/questions/4793604/how-to-do-insert-after-in-javascript-without-using-a-library
 *
 * @param  {Node} newNode       Node to add.
 * @param  {Node} referenceNode Node to add newNode after.
 */
export function insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

/**
 * Loop through an array/nodelist and call the passed callback function.
 * @param  {Array}   	array    	Array to be looped over.
 * @param  {Function} 	callback 	Callback function.
 * @param  {???}   		scope
 */
export function forEach(array, callback, scope) {
	for (var i = 0; i < array.length; i++) {
		callback.call(scope, i, array[i]);
	}
}
