/**
 * Import dependencies.
 */
import $ from 'jquery';
import * as helpers from './helpers';
import * as els from './elements';
import * as settings from './settings';

/**
 * Menu items that have submenus.
 * @type {Array}
 */
const menuItemsWithChildren = els.mainMenu.querySelectorAll('.menu-item-has-children');

/**
 * All submenus.
 * @type {[type]}
 */
const submenus = els.mainMenu.querySelectorAll('.sub-menu');

/**
 * Screensize that mobile menu is enabled/disabled
 * @type {Int}
 */
const menuBreakPoint = settings.breakpoints.md.min;

/**
 * Class applied to <html> when the menu is open.
 * @type {String}
 */
const mobileMenuClass = 'mobile--menu';

/**
 * Placeholder for clone of .utilities
 * @type {HTMLElement}
 */
const utilitiesClone = els.utilities.cloneNode(true);

/**
 * Class used for menu item triggers.
 * @type {String}
 */
const menuItemTriggerClass = 'menu-item__trigger';

/**
 * Helper function to create a menu item trigger.
 * @type {HTMLElement}
 */
function createMenuItemTrigger() {
	const trigger = document.createElement('I');
	trigger.className = menuItemTriggerClass + ' fa fa-angle-down';

	return trigger;
}

/**
 * Check if current submenu is fully within the viewport. If not, add a class so that it can be styled accordingly.
 * @param  {Event} e
 */
function checkSubmenuPosition(e) {
	const submenu = this.querySelector('.sub-menu');
	if ( !helpers.isElementInViewport(submenu) && window.innerWidth >= menuBreakPoint) {
		this.classList.add('submenu-offscreen');
	}
}

/**
 * Toggle mobile submenus on click.
 * @param  {Event} e
 */
function delegateTriggerClick(e) {
	/**
	 * Prevents further propagation of the current event in the capturing and bubbling phases.
	 */
	e.stopPropagation();

	/**
	 * Check if the event target contains the menu item trigger class.
	 */
	if ( e.target.classList.contains(menuItemTriggerClass) ) {
		/**
		 * Prevent the click from linking to a new page.
		 */
		e.preventDefault();

		/**
		 * Get the sibling submenu.
		 * @type {HTMLElement}
		 */
		const submenu = e.target.previousElementSibling;

		if ( submenu.style.display !== 'block' ) {
			$(submenu).slideDown();
		} else {
			$(submenu).slideUp();
		}

		/**
		 * Toggle Font Awesome icon classes.
		 */
		e.target.classList.toggle('fa-angle-down');
		e.target.classList.toggle('fa-angle-up');
	}
}

/**
 * Add event listeners for menu items with submenus.
 */
helpers.forEach(menuItemsWithChildren, (i, item) => {
	item.addEventListener('mouseenter', checkSubmenuPosition);
	item.addEventListener('click', delegateTriggerClick);
});

/**
 * Initialize the mobile menu
 * @param  {Event} e
 */
function handleMenuView(e) {
	/**
	 * Make sure we are on mobile before proceeding.
	 */
	if ( window.innerWidth < menuBreakPoint ) {
		/**
		 * Make sure the mobile menu hasn't already been initialized.
		 */
		if ( !els.html.classList.contains(mobileMenuClass) ) {
			/**
			 * Add the mobile menu class to the <html> element.
			 */
			els.html.classList.add(mobileMenuClass);

			/**
			 * Add a clone of the utilities to the main menu.
			 */
			els.mainMenu.appendChild(utilitiesClone);

			/**
			 * Add padding to bottom of menu matching the height of the utilities menu.
			 */
			els.mainMenu.style.paddingBottom = utilitiesClone.offsetHeight + 'px';

			/**
			 * Add menu item triggers.
			 */
			helpers.forEach(menuItemsWithChildren, (i, item) => {
				if ( item.querySelectorAll('.' + menuItemTriggerClass).length === 0 ) {
					const trigger = document.createElement('I');
					trigger.className = menuItemTriggerClass + ' fa fa-angle-down';
					item.appendChild(trigger);
				}
			});
		}
	} else {
		/**
		 * Remove the mobile menu class to the <html> element.
		 */
		els.html.classList.remove(mobileMenuClass);

		/**
		 * Remove padding from menu on desktop.
		 */
		els.mainMenu.style.paddingBottom = 0 + 'px';

		/**
		 * Hide all submenus and reset menu item trigger classes.
		 */
		helpers.forEach(submenus, (i, submenu) => {
			submenu.style.display = '';
			const trigger = submenu.nextElementSibling;
			if ( trigger !== null ) {
				trigger.classList.remove('fa-angle-up');
				trigger.classList.add('fa-angle-down');
			}
		});
	}
}

function handleMultipleMenuAncestors(e) {
	const ancestorItems = document.querySelectorAll('.main-menu > .current-menu-ancestor');
	if(ancestorItems.length > 0) {
		ancestorItems[0].classList.add('current-menu-ancestor--primary');
	}
}

window.addEventListener('DOMContentLoaded', function(e) {
	handleMenuView(e);
	handleMultipleMenuAncestors(e);
});
window.addEventListener('resize', helpers.debounce(handleMenuView));

/**
 * Close the mobile menu when you click off of it.
 * @param  {Event} e
 */
function maybeCloseMobileMenu(e) {
	if ( !$(e.target).closest('.menu-container, .navicon').length && !$(e.target).is('.menu-container, .navicon') ) {
		els.html.classList.remove('html--menu-open');
		els.header.classList.remove('site-header--menu-open');
		els.navicon.querySelector('.navicon__trigger').classList.remove('navicon__trigger--open');
	}
}

document.addEventListener('click', maybeCloseMobileMenu);
